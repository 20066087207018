const black = '#000000';
const white = '#ffffff';

const colors = {
  black,
  white,
  neutral: {
    0: white,
    5: '#fafafa',
    10: '#f5f5f5',
    15: '#f0f0f0',
    20: '#e5e5e5',
    30: '#d4d4d4',
    40: '#a3a3a3',
    50: '#737373',
    60: '#525252',
    70: '#404040',
    80: '#262626',
    90: '#171717',
    95: '#0a0a0a',
    100: black,
  },
  neutralDark: {
    0: black,
    5: '#0d0d0d',
    10: '#1a1a1a',
    15: '#262626',
    20: '#333333',
    30: '#4d4d4d',
    40: '#666666',
    50: '#808080',
    60: '#999999',
    70: '#b3b3b3',
    80: '#cccccc',
    90: '#e6e6e6',
    95: '#f2f2f2',
    100: white,
  },
  red: {
    50: '#ffebed',
    100: '#ffe5e7',
    200: '#fc938e',
    300: '#f56661',
    400: '#df4840',
    500: '#ff3b36',
    600: '#ff1e0a',
    700: '#f50010',
    750: '#e3000b',
    800: '#d70000',
    900: '#c90000',
    950: '#990000',
  },
  orange: {
    50: '#fcf3e1',
    100: '#ffedcc',
    200: '#f3cc83',
    300: '#efb851',
    400: '#eca92e',
    450: '#ffa724',
    500: '#fc901d',
    600: '#e78f0d',
    700: '#ff8629',
    750: '#ff7a00',
    800: '#f56a00',
    850: '#e65a00',
    900: '#c25400',
    950: '#0d0600',
  },
  green: {
    50: '#e4f9e8',
    100: '#beefc7',
    200: '#c0e997',
    300: '#a4e069',
    400: '#8dd842',
    500: '#76d100',
    600: '#66c100',
    650: '#0ed100',
    700: '#4eac00',
    750: '#009000',
    800: '#007600',
    850: '#005800',
    900: '#14532d',
    950: '#052e16',
  },
  blue: {
    25: '#f0f5ff',
    50: '#e8ebfb',
    100: '#c5ccf6',
    200: '#9eacf0',
    300: '#738bea',
    400: '#4f70e5',
    500: '#1f55df',
    600: '#481fdf',
    700: '#3015d7',
    800: '#000ed1',
    900: '#0000ca',
    950: '#000764',
  },
  brightPink: {
    50: '#fde5f1',
    100: '#f9bddc',
    200: '#f892c5',
    300: '#f864ac',
    400: '#f93c97',
    500: '#fb0081',
    600: '#e8007d',
    700: '#d10076',
    800: '#bb0171',
    900: '#940068',
    950: '#4f0034',
  },
  mutedBlue: {
    25: '#f7f6ff',
    50: '#e5e6ee',
    100: '#bdc1d6',
    200: '#6b749f',
    300: '#939aba',
    400: '#313d7b',
    500: '#4e588d',
    600: '#2c3673',
    700: '#242e69',
    800: '#1d255c',
    900: '#111546',
    950: '#020617',
  },
  blueViolet: {
    25: '#f8e0fe',
    50: '#f0e5ff',
    100: '#d8c1f5',
    200: '#bd98f0',
    300: '#a26bea',
    400: '#8d46e5',
    500: '#7617df',
    600: '#6b11d9',
    700: '#5b00d1',
    750: '#4f00c9',
    800: '#4900a8',
    900: '#370080',
    950: '#2e1065',
  },
  mutedPurple: {
    50: '#e8e8f3',
    100: '#c6c4e1',
    200: '#a29ecd',
    300: '#4a3792',
    400: '#7f79b9',
    500: '#665caa',
    600: '#503f9b',
    700: '#422e86',
    800: '#3b237a',
    900: '#2e1065',
    950: '#3b0764',
  },
} as const;

export default colors;
